<template>
  <div class="hold-transition">
    <loading v-if="loading || cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Crear Programación</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Hidrocarburos</router-link>
                  </li>
                  <li class="breadcrumb-item active">Crear Programación</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <form @submit="saveProgramacion" onsubmit="return false">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="">Nominaciones</label>

                        <v-select
                          :class="[
                            $v.form.idNominacion.id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.idNominacion"
                          label="nombre"
                          :options="nominacionesOption"
                          placeholder="Nominacion"
                        >
                        </v-select>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="">Fecha inicial</label>

                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_inicial"
                          :class="
                            $v.form.fecha_inicial.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @input="validaFechas"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="">Fecha final</label>

                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_final"
                          :class="
                            $v.form.fecha_final.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @input="validaFechas"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="">Estado</label>
                        <select
                          v-model="form.estado"
                          class="form-control form-control-sm"
                          :class="
                            $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          disabled
                        >
                          <option
                            v-for="r in listas"
                            v-bind:key="r.id"
                            v-bind:value="r.numeracion"
                          >
                            {{ r.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Observación</label>
                        <textarea
                          v-model="form.observacion"
                          class="form-control form-control-sm"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <router-link to="/Hidrocarburos/Programaciones">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        @click="newItem = true"
                        v-if="
                          $store.getters.can('hidrocarburos.programacion.index')
                        "
                      >
                        <i class="fas fa-reply"></i><br />Volver
                      </button>
                    </router-link>

                    <button
                      type="submit"
                      class="btn btn-primary"
                      v-show="!$v.form.$invalid"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.programacion.create'
                        ) ||
                          $store.getters.can('hidrocarburos.programacion.edit')
                      "
                    >
                      <i class="fas fa-paper-plane"></i><br />Guardar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";

export default {
  name: "ProgramacionCreate",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      guardar: false,
      form: {
        fecha_inicial: "",
        fecha_final: "",
        estado: 1,
        observacion: "",
        idNominacion: "",
      },
      nominacionesOption: [],
      sitio: {},
    };
  },
  beforeMount() {
    this.actGetNominacionesListFecha().then(() => {
      this.nominacionesListFecha.forEach((row) => {
        if (row.estado == 2) {
          this.nominacionesOption.push({
            id: row.id,
            nombre: row.nombre,
          });
        }
      });
    });
  },
  validations: {
    form: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
      estado: {
        required,
      },
      idNominacion: {
        id: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapState("modOperacion", ["listas"]),
    ...mapState("modHidrocarburos", ["loading", "nominacionesListFecha"]),
  },
  methods: {
    ...mapActions("modOperacion", ["actGetListas"]),
    ...mapActions("modHidrocarburos", [
      "actSaveProgramacion",
      "actGetNominacionesListFecha",
    ]),

    validaFechas() {
      if (this.form.fecha_inicial) {
        const hoy = new Date();
        const fecha_inicial = new Date(this.form.fecha_inicial);
        fecha_inicial.setDate(fecha_inicial.getDate());

        if (fecha_inicial < hoy) {
          this.form.fecha_inicial = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser mayor a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          let valorHoy = hoy.getDate();
          let valorFecha = fecha_inicial.getDate();
          if (valorHoy == valorFecha) {
            var horaActual = hoy.getHours();
            if (horaActual >= 22) {
              this.form.fecha_inicial = null;
              this.$swal({
                icon: "error",
                title: `No puede programar  con esta fecha despues de las 10:00 PM`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          }
        }
      }

      if (this.form.fecha_inicial && this.form.fecha_final) {
        const fecha_menor = new Date(this.form.fecha_inicial);
        const fecha_mayor = new Date(this.form.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    calcularHora() {
      const hoy = new Date();
      const fecha_inicial = new Date(this.form.fecha_inicial);
      let valorHoy = hoy.getDate();
      let valorFecha = fecha_inicial.getDate();
      if (valorHoy == valorFecha) {
        var horaActual = hoy.getHours();
        if (horaActual >= 22) {
          this.form.fecha_inicial = null;
          this.guardar = false;
          this.$swal({
            icon: "error",
            title: `No puede programar  con esta fecha inicial despues de las 10:00 PM`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.guardar = true;
        }
      } else {
        this.guardar = true;
      }
    },

    async saveProgramacion() {
      await this.calcularHora();
      if (this.guardar) {
        let data = [];
        data = {
          nominacion_id: this.form.idNominacion.id,
          fecha_inicial: this.form.fecha_inicial,
          fecha_final: this.form.fecha_final,
          observacion: this.form.observacion,
          estado: this.form.estado,
        };
        //alert(JSON.stringify(data));
        this.actSaveProgramacion(data).then((response) => {
          if (response.id) {
            this.$swal({
              icon: "success",
              title: "La programación base se creó con exito",
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });

            this.$router.push({
              path: "/Hidrocarburos/ProgramacionesEdit/" + response.id,
            });
          }

          if (response.status == "error") {
            this.$swal({
              icon: "error",
              title: response.msg,
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          }
        });
      }
    },
  },
};
</script>
